import React from "react";
import Hero from "@insightfulscience/atomic-react/Hero";
import HeroTitle from "@insightfulscience/atomic-react/Hero/Title";
import HeroDescription from "@insightfulscience/atomic-react/Hero/Description";
import HeroAction from "@insightfulscience/atomic-react/Hero/Action";
import { HeroCol, HeroRow } from "@insightfulscience/atomic-react/Hero/Grid";
import HeroButtonLink from "@insightfulscience/atomic-react/Hero/ButtonLink";
import { bool, oneOf, shape, string } from "prop-types";
import { Col, Row } from "@insightfulscience/atomic-react/Grid";
import Image from "@insightfulscience/atomic-react/Image";
import { classes } from "@insightfulscience/atomic-react/utils/classes";
import WistiaVideoEmbed from "@insightfulscience/contentful/ui/WistiaVideoEmbed";

const fileType = shape({
	fields: shape({
		title: string,
		description: string,
		file: shape({
			url: string,
			fileName: string,
			contentType: string,
		}),
	}),
});

const HeroInfo = ({
	title,
	lead,
	primaryCtaLink,
	primaryCtaText,
	secondaryCtaLink,
	secondaryCtaText,
	isLeft,
	fontColor = "black",
}) => {
	const isHasActions = (primaryCtaText && primaryCtaLink) || (secondaryCtaText && secondaryCtaLink);

	return (
		<>
			<HeroTitle sx={{ fontColor }}>{title}</HeroTitle>
			{lead && <HeroDescription sx={{ fontColor }}>{lead}</HeroDescription>}
			{isHasActions && (
				<HeroAction center={!isLeft}>
					<HeroRow>
						{primaryCtaText && primaryCtaLink && (
							<HeroCol>
								<HeroButtonLink href={primaryCtaLink} styleType="primary">
									{primaryCtaText}
								</HeroButtonLink>
							</HeroCol>
						)}
						{secondaryCtaText && secondaryCtaLink && (
							<HeroCol>
								<HeroButtonLink href={secondaryCtaLink} styleType="secondary" isOutline2>
									{secondaryCtaText}
								</HeroButtonLink>
							</HeroCol>
						)}
					</HeroRow>
				</HeroAction>
			)}
		</>
	);
};

HeroInfo.propTypes = {
	title: string,
	lead: string,
	primaryCtaLink: string,
	primaryCtaText: string,
	secondaryCtaLink: string,
	secondaryCtaText: string,
	isLeft: bool,
	fontColor: string,
};

const HeroBlock = props => {
	const { backgroundColor, backgroundImage, foregroundImage, foregroundVideo, alignment } = props;
	const isVideoExist = !!foregroundVideo?.fields?.videoUrl;
	const isImgExist = !!foregroundImage?.fields?.file?.url;
	const isNeedShowRightCol = isVideoExist || isImgExist;
	const isLeft = alignment === "Left";
	const rightColumnConf = isLeft ? { md: 6, lg: 7 } : { md: 12, lg: 12 };
	const leftColumnConf = isLeft ? { md: 6, lg: 5 } : { md: 12, lg: 12 };

	return (
		<Hero
			spacing={{ py: 18 }}
			backgroundImage={backgroundImage?.fields?.file?.url}
			utility={{ bgColor: backgroundColor }}
		>
			<Row mx utility={{ align: { items: "center" } }}>
				<Col
					{...(isNeedShowRightCol && leftColumnConf)}
					smOrder={1}
					mdOrder={0}
					className={classes(isLeft && "text-left")}
					spacing={{ mb: isNeedShowRightCol ? 10 : 0, sm: { mb: 0 } }}
				>
					<HeroInfo {...props} isLeft={isLeft} />
				</Col>
				{isNeedShowRightCol && (
					<Col {...rightColumnConf} smOrder={0} mdOrder={1} spacing={{ sm: { mb: 10 } }}>
						{isVideoExist ? (
							<WistiaVideoEmbed url={foregroundVideo.fields.videoUrl} />
						) : (
							<Image src={foregroundImage?.fields?.file?.url} isBaseUrl={false} />
						)}
					</Col>
				)}
			</Row>
		</Hero>
	);
};

HeroBlock.propTypes = {
	alignment: oneOf(["Left", "Center"]),
	backgroundColor: string,
	backgroundImage: fileType,
	foregroundImage: fileType,
	foregroundVideo: shape({
		fields: shape({
			videoUrl: string,
		}),
	}),
	title: string,
	lead: string,
	primaryCtaLink: string,
	primaryCtaText: string,
	secondaryCtaLink: string,
	secondaryCtaText: string,
};

export default HeroBlock;
