import React from "react";
import contentfulStandardApp from "@insightfulscience/contentful/Apps/Standard";
import { useLocale, WithLocale } from "@insightfulscience/i18";
import PageMeta from "@insightfulscience/smart-react/PageMeta";
import pipe from "@insightfulscience/shared-utils/fn/pipe";
import SiteLayout from "@src/SiteLayout";
import AppError, { withErrorHandling } from "@src/AppError";
import SectionTryForFree from "@src/SectionTryForFree";
import BlockLinks from "@src/Contentful/BlockLinks";
import VisualCopyBlock from "@src/Contentful/VisualCopyBlock";
import HeroBlock from "@src/Contentful/HeroBlock";
import resources from "../locales/standard-page";

const standardPageLayout = ({ children }) => {
	const [t] = useLocale("standard-pages");
	return (
		<SiteLayout hero>
			<PageMeta>
				<PageMeta.Twitter image={t("META_TWITTER_IMAGE")} isImageBaseUrl />
			</PageMeta>
			{children}
			<SectionTryForFree />
		</SiteLayout>
	);
};

export default pipe(
	contentfulStandardApp({
		Layout: pipe(standardPageLayout, WithLocale({ resources })),
		AppError,
		contentfulProps: {
			environment: process.env.CTF_ENV,
			space: process.env.CTF_SPACE,
			accessToken: process.env.CTF_TOKEN,
			proxy: null,
		},
		pageProps: {
			brandCode: "sg",
			siteDomain: "https://www.snapgene.com",
			resources,
			components: {
				linksBlock: BlockLinks,
				visualCopyBlock: VisualCopyBlock,
				heroBlock: HeroBlock,
			},
		},
	}),
	withErrorHandling,
);
